import { gsap } from 'gsap';

export const contactAnimations = (locoScroll) => {
    if (document.querySelector('body#contactPage')) {
        const timeline = gsap.timeline({ defaults: { ease: 'power4.inOut', duration: 2 } });
        timeline
            .to('.textTitle .line span', {
                ease: 'power4.out',
                delay: 0,
                duration: 1.8,
                transform: 'translateY(0) skewY(0)',
                stagger: {
                    amount: 0.4,
                },
            })
            .to('[data-opacity]', {
                opacity: 1,
                delay: -1.4,
                stagger: {
                    amount: 0.4,
                },
            })
            .to('.rodoLink_2', {
                opacity: 1,
                delay: -1.4,
            });
    }
};
