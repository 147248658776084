import $ from 'jquery';

export default () => {
    const burger = document.querySelector('.hamburgerBtn');
    const menu = document.querySelector('.menuContainer');
    const scroller = document.querySelector('[data-scroll-container]');
    if (burger && menu) {
        burger.addEventListener('click', () => {
            if (burger.classList.contains('active')) {
                const linksContainers = document.querySelectorAll('.linkContainer');
                linksContainers.forEach((link, index) => {
                    setTimeout(() => {
                        link.classList.add('closeLink');
                    }, index * 100);
                });
                const allLinksSocial = document.querySelectorAll('.rodoLink, .facebookLink');
                allLinksSocial.forEach((link) => {
                    link.classList.add('opacity-0');
                });
                setTimeout(() => {
                    setInActiveBurger(burger);
                    menu.classList.remove('active');
                    setTimeout(() => {
                        scroller && scroller.classList.remove('opacity-0');
                        setTimeout(() => {
                            const allLinks = document.querySelectorAll('.linkContainer');
                            allLinks.forEach((link) => {
                                link.classList.remove('closeLink');
                                link.classList.add('disableLink');
                            });
                        }, 300);
                    }, 1600);
                }, 800);
            } else {
                scroller && scroller.classList.add('opacity-0');
                setActiveBurger(burger);
                setTimeout(() => {
                    menu.classList.add('active');
                    setTimeout(() => {
                        const allLinks = document.querySelectorAll('.disableLink');
                        allLinks.forEach((link, index) => {
                            setTimeout(() => {
                                link.classList.remove('disableLink');
                            }, 230 * index);
                        });
                    }, 2300);
                    setTimeout(() => {
                        const allLinks = document.querySelectorAll('.rodoLink, .facebookLink');
                        allLinks.forEach((link) => {
                            link.classList.remove('opacity-0');
                        });
                    }, 3500);
                }, 0);
            }
        });
    }
    $('.linkContainer').hover(
        function () {
            $(this).addClass('hover');
            $('.linkContainer').each(function () {
                if (!$(this).hasClass('hover')) {
                    $(this).addClass('opacity');
                }
            });
        },
        function () {
            $(this).removeClass('hover');
            $('.linkContainer').each(function () {
                $(this).removeClass('opacity');
            });
        }
    );
};

export const closeMenu = () => {
    const burger = document.querySelector('.hamburgerBtn');
    const menu = document.querySelector('.menuContainer');
    const scroller = document.querySelector('[data-scroll-container]');

    if (burger && menu) {
        if (burger.classList.contains('active')) {
            setInActiveBurger(burger);
            menu.classList.remove('active');
            setTimeout(() => {
                scroller && scroller.classList.remove('opacity-0');
                setTimeout(() => {
                    const allLinks = document.querySelectorAll('.linkContainer');
                    allLinks.forEach((link) => {
                        link.classList.add('disableLink');
                    });
                    const allLinksSocial = document.querySelectorAll('.rodoLink, .facebookLink');
                    allLinksSocial.forEach((link) => {
                        link.classList.add('opacity-0');
                    });
                }, 300);
            }, 300);
        }
    }
};

const setActiveBurger = (btn) => {
    btn.classList.add('stepOne');
    setTimeout(() => {
        btn.classList.add('stepTwo');
        setTimeout(() => {
            btn.classList.add('active');
        }, 350);
    }, 350);
};
const setInActiveBurger = (btn) => {
    btn.classList.remove('active');
    btn.classList.add('stepTwo');
    setTimeout(() => {
        btn.classList.remove('stepTwo');
        btn.classList.add('stepOne');
        setTimeout(() => {
            btn.classList.remove('stepOne');
        }, 350);
    }, 350);
};
