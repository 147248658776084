import { gsap } from 'gsap';
import CustomEase from 'gsap/dist/CustomEase';
import { HPAboutSectionAnimation } from './sections/aboutsection';
import { feeAnimations } from './sections/fee';
import { contactAnimations } from './sections/constact';

export const hpAnimation = (locoScroll) => {
    if (document.querySelector('body#homePage')) {
        const scrollDown = document.querySelector('.buttonScroll');
        scrollDown.addEventListener('click', () => {
            locoScroll.scrollTo('.about-homepage');
        });
        /** Opacity Animations SET OPACITY 0 */
        const opacityElements = document.querySelectorAll('[data-opacity-animation]');
        opacityElements.forEach((el) => {
            gsap.set(el, { opacity: 0 });
        });

        const scrollTimeline = gsap.timeline({ defaults: { ease: 'power3.inOut', duration: 3, repeat: 99999 } });
        scrollTimeline.fromTo(
            '.scrollDown .inner',
            { y: '-100%' },
            {
                y: '155%',
            }
        );
        const tl = gsap.timeline({ defaults: { ease: 'power4.inOut', duration: 2 } });
        tl.to('[data-clip-asw]', {
            transform: 'translate(-50%, -50%) scale(1)',
            opacity: 1,
            y: 0,
            duration: 2.2,
            ease: CustomEase.create('cubic', '0.0, 0, 0.34, 0.99'),
        });
        tl.from('.header-homepage .line span', 1.65, {
            y: 100,
            ease: 'power1.easeInOut',
            delay: -1,
            stagger: {
                amount: 0.9,
            },
        })
            .to(
                '.hamburgerBtn .inner',
                {
                    // transform: 'scale(1)',
                    // transform: 'translate(-50%, calc(-50% - 4.5px)) scale(1, 1)',
                    duration: 1.6,
                    scaleX: 1,
                },
                '-=2'
            )
            .to(
                '[data-clip-four]',
                {
                    ease: 'power1.easeOut',
                    transform: 'scaleX(1)',
                    y: 0,
                    duration: 1.2,
                },
                '-=0.0'
            )
            .to(
                '.scrollDown',
                {
                    opacity: 1,
                    ease: 'power1.easeOut',
                    transform: 'translateY(0)',
                    duration: 2,
                },
                '-=2'
            )
            .to(
                '.scrollDown .inner',
                {
                    ease: 'power1.easeOut',
                    opacity: 1,
                    duration: 2,
                },
            );

        gsap.to('.data-clip-img', {
            scrollTrigger: {
                scroller: '[data-scroll-container]',
                trigger: '.data-clip-img',
                start: 'top center',
            },
            'clip-path': 'polygon(0% 100%,100% 100%,100% 0%,0% 0%)',
            transform: 'translateY(0) scale(1)',
            ease: CustomEase.create('cubic', '.0,.0,.0,1'),
            duration: 2.2,
            delay: 1.2
        });

        feeAnimations();
        HPAboutSectionAnimation();
        contactAnimations();
    } else {
        const tl = gsap.timeline({ defaults: { ease: 'power4.inOut', duration: 2 } });

        tl.to(
            '.hamburgerBtn .inner',
            {
                duration: 1.6,
                scaleX: 1,
            },
            '-=2'
        ).to(
            '[data-clip-four]',
            {
                transform: 'scaleX(1)',
                y: 0,
                duration: 1.6,
            },
            '-=1'
        );
    }
};
