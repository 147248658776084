import { gsap } from 'gsap';
export const contactAnimations = () => {
    const timeline = gsap.timeline({
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.services-homepage',
            start: 'top center',
        },
        defaults: { ease: 'power4.inOut', duration: 2 },
    });
    timeline.to('.servicesLink .line span', {
        ease: 'power4.out',
        delay: 0,
        duration: 1.8,
        transform: 'translateY(0) skewY(0)',
        stagger: {
            amount: 0.6,
        },
    });

    timeline.to(
        '.bb',
        {
            x: 0,
        },
        '-=2.5'
    );
    timeline.to('[data-third]', {
        opacity: 1,
        delay: '-=0.5',
    });
    timeline.to(
        '[data-opacity-arrow]',
        {
            opacity: 1,
        },
        '-=1.2'
    );
    const tl2 = gsap.timeline({
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.contactDataWrapper',
            start: 'top 90%',
        },
        defaults: { ease: 'power4.inOut', duration: 2 },
    });
    tl2.to('.line span', {
        delay: 0,
        duration: 1.8,
        transform: 'translateY(0) skewY(0)',
        stagger: {
            amount: 0.6,
        },
    });
    tl2.to('[ data-contact-opacity]', {
        opacity: 1,
        delay: '-1',
    });
    tl2.to('[data-contact-opacity2]', {
        opacity: 1,
        delay: '-1',
    });
};
