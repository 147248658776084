import { gsap } from 'gsap';

export const adviceAnimations = (locoScroll) => {
    if (document.querySelector('body#advicePage')) {
        const timeline = gsap.timeline({ defaults: { ease: 'power4.inOut', duration: 2 } });
        timeline
            .to('.titleCol .line span', {
                ease: 'power4.out',
                delay: 0,
                duration: 1.8,
                transform: 'translateY(0) skewY(0)',
                stagger: {
                    amount: 0.4,
                },
            })
            .to('.titleCol .line', {
                overflow: window.innerWidth > 900 ? 'hidden' : 'visible',
                delay: -1.4,
            })
            .to('.contentCol .line span', {
                ease: 'power4.out',
                delay: -2,
                duration: 1.8,
                transform: 'translateY(0) skewY(0)',
                stagger: {
                    amount: 0.4,
                },
            })
            .to('.mobileText', {
                opacity: 1,
                duration: 2,
                delay: -2,
            })
            .to('.textCol', {
                opacity: 1,
                delay: -2,
                duration: 1.8,
            })
            .to('.rodoLink_2', {
                opacity: 1,
                delay: -2,
                duration: 1.8,
            });
    }
};
