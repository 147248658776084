import Cookies from 'js-cookie';

export default () => {
    const rodo = document.querySelector('.cookiesContainer');
    const cookie_delay = 3000;

    if (!Cookies.get('rodo-notyfication')) {
        setTimeout(function () {
            rodo.classList.add('cookiesContainer-active');
        }, cookie_delay);

        document.querySelector('#cookiesClose').addEventListener('click', function () {
            Cookies.set('rodo-notyfication', 'accept', { expires: 365 });
            rodo.classList.remove('cookiesContainer-active');
        });
    }
};
