import { gsap } from 'gsap';
export const feeAnimations = () => {
    if (navigator.platform.indexOf('Mac') > -1) {
        const el = document.querySelector('.fee-homepage .hugeTitle .line span');
        el.classList.add('lineheightone');
    }
    gsap.to('.fee-homepage .hugeTitle .line span', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        ease: 'power4.out',
        delay: 0,
        duration: 3,
        transform: 'translateY(0)',
        stagger: {
            amount: 0.5,
        },
    });
    gsap.to('.fee-homepage .nav-item .line span', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        ease: 'power4.out',
        delay: 1.2,
        duration: 2,
        transform: 'translateY(0)',
        stagger: {
            amount: 0,
        },
    });
    gsap.to('.fee-homepage .tab-pane.active .textTitle .line span', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        ease: 'power4.out',
        delay: 3.2,
        duration: 2,
        transform: 'translateY(0)',
        stagger: {
            amount: 0.6,
        },
    });
    if (window.innerWidth < 1400) {
        gsap.to('[data-fee-animxl]', {
            scrollTrigger: {
                scroller: '[data-scroll-container]',
                trigger: '.fee-homepage',
                start: 'top center',
            },
            opacity: 1,
            duration: 1.5,
            delay: 2.8,
        });
    }
    gsap.to('[data-fee-four]', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        opacity: 1,
        duration: 1.5,
        delay: 1.8,
    });
    gsap.to('[data-opacity-fee-one]', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        opacity: 1,
        duration: 1.5,
        delay: 3,
    });
    gsap.to('[data-opacity-fee-two]', {
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.fee-homepage',
            start: 'top center',
        },
        opacity: 1,
        duration: 1.5,
        delay: 3.2,
    });
    const tabs = document.querySelectorAll('#pills-tab button.nav-link');
    tabs.forEach((tab) => {
        tab.addEventListener('hide.bs.tab', function (event) {
            // setTimeout(() => {
            gsap.to(`${event.relatedTarget.getAttribute('data-bs-target')} .textTitle .line span`, {
                transform: 'translateY(0)',
                duration: 1,
                delay: 0.5,
                stagger: {
                    amount: 0.6,
                },
            });

            setTimeout(() => {
                gsap.set(`${event.target.getAttribute('data-bs-target')} .textTitle .line span`, {
                    transform: 'translateY(900%)',
                });
            }, 300);
        });
    });
};
