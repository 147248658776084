import 'bootstrap';

//styles
import '../sass/main.scss';

//utils
import cookies from './utils/cookies';
import navbar, { closeMenu } from './utils/navbar';
import LocomotiveScroll from 'locomotive-scroll';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { hpAnimation } from './utils/animations/hp';
import button from 'bootstrap/js/src/button';
import { servicesAnimations } from './utils/animations/services';
import { adviceAnimations } from './utils/animations/advice';
import { contactAnimations } from './utils/animations/contact';
import {MatchHeight} from "js-match-height";
let scroll;
window.addEventListener('DOMContentLoaded', () => {


    new MatchHeight('[data-math-height]', { timeout:100 });


    document.querySelector('.menuContainer').classList.remove('d-none');
    const rodo = document.querySelector('.rodoSection');
    if (rodo) {
        setTimeout(() => {
            rodo.classList.add('active');
        }, 500);
    }

    // kick off the polyfill!
    // smoothscroll.polyfill();
    /*------
    utils
    ------*/
    // cookies();

    navbar();
    gsap.registerPlugin(ScrollTrigger);

    scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        multiplier: 0.45,
    });
    ScrollTrigger.scrollerProxy('[data-scroll-container]', {
        scrollTop(value) {
            return arguments.length ? scroll.scrollTo(value, 0, 0) : scroll.scroll.instance.scroll.y;
        },
        getBoundingClientRect() {
            return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
        },
    });
    scroll.on('scroll', ScrollTrigger.update);
    const urlParams = new URLSearchParams(window.location.search);
    const tag = urlParams.get('target');
    if (tag) {
        const element = document.querySelector(`#${tag}`);
        if (element) {
            scroll.scrollTo(element, { offset: -200, duration: 0 });
        }
    }
    hpAnimation(scroll);
    serviceTabsUpdate(scroll);
    servicesAnimations(scroll);
    adviceAnimations(scroll);
    contactAnimations(scroll);

    scroll.on('scroll', (e) => {
        closeMenu();
        const header = document.querySelector('#header .texts');
        const buttonScroll = document.querySelector('#header .scrollDown');
        if (buttonScroll && header) {
            if (e.scroll.y > 50) {
                buttonScroll.classList.add('opacity-0');
            } else {
                buttonScroll.classList.remove('opacity-0');
            }
            // const newHeight = (window.innerHeight - e.scroll.y) / window.innerHeight;
            // header.style.transform = `scale(${newHeight > 0.5 ? newHeight : 0.5})`;
        }
    });
    const rodoPage = document.querySelector('#rodoPage');
    if (rodoPage) {
        const indicator = rodoPage.querySelector('.rodoContent .indicator');
        indicator.style.minHeight = '25px';
        const scrollContainer = rodoPage.querySelector('[data-scroll-container]');
        const content = rodoPage.querySelector('.rodoContent');

        // -----------------
        // od wysokości paragrafu wysokość przesunięcia.
        // -----------------
        scroll.on('scroll', (e) => {
            const percent = e.scroll.y / e.limit.y;
            if (window.innerWidth > 2000) {
                indicator.style.height = `calc(${ content.clientHeight - (e.limit.y + content.clientHeight * 0.15)}px)`;
                gsap.to(indicator, { y: e.scroll.y + content.clientHeight * percent * 0.15, delay: 0, duration: 0 });
            } else if (window.innerWidth < 1450) {
                indicator.style.height = `calc(${ content.clientHeight - (e.limit.y + content.clientHeight * 0.11)}px)`;
                gsap.to(indicator, { y: e.scroll.y + content.clientHeight * percent * 0.11, delay: 0, duration: 0 });
            } else {
                indicator.style.height = `calc(${ content.clientHeight - (e.limit.y + content.clientHeight * 0.07)}px)`;
                gsap.to(indicator, { y: e.scroll.y + content.clientHeight * percent * 0.07, delay: 0, duration: 0 });
            }
        });
        }

    ScrollTrigger.addEventListener('refresh', () => scroll.update());
    ScrollTrigger.refresh();

    const years = document.querySelectorAll('.year');
    if (years.length > 0) {
        years.forEach((el) => {
            el.innerHTML = new Date().getFullYear().toString();
        });
    }
    const links = document.querySelectorAll('.anchorLink');
    if (links.length > 0) {
        links.forEach((el) => {
            el.addEventListener('click', () => {
                const tag = el.getAttribute('data-href');
                if (tag) {
                    const element = document.querySelector(`#${tag}`);
                    if (element) {
                        closeMenu();
                        scroll.scrollTo(element, { offset: -100, duration: 2500 });
                    }
                }
            });
        });
    }
});

const serviceTabsUpdate = (scroll) => {
    if (document.querySelector('body#servicesPage')) {
        const allTabs = document.querySelectorAll('.tabsCol .nav-link');
        allTabs.forEach((link) => {
            link.addEventListener('click', () => {
                scroll.scrollTo('.servicesSection');
                setTimeout(() => {
                    scroll.update();
                }, 500);
            });
        });
    }
};

window.addEventListener('DOMContentLoaded', () => {
    const menuItem = [...document.querySelectorAll('.listLink')];

    menuItem.forEach((item) => {
        // Get the div and span
        let word = item.children[0].children[0].innerText.split('');
        // console.log(word);

        item.children[0].innerText = '';
        word.forEach((letter, idx) => {
            // Each letter will be added the index
            item.children[0].innerHTML += `<span style="--index: ${idx};">${letter}</span>`;
        });

        let cloneDiv = item.children[0].cloneNode(true);

        // Style the clone div
        cloneDiv.style.position = 'absolute';
        cloneDiv.style.left = '0';
        cloneDiv.style.top = '0';
        item.appendChild(cloneDiv);
    });

    // console.log(menuItem);
});
