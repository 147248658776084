import { gsap } from 'gsap';
import $ from 'jquery';

export const servicesAnimations = (locoScroll) => {
    if (document.querySelector('body#servicesPage')) {
        tabsMobile();
        const tl = gsap.timeline({ defaults: { ease: 'power4.inOut', duration: 2 } });
        tl.to('[data-first]', {
            opacity: 1,
        })
            .to('[data-second]', {
                opacity: 1,
                delay: '-1.5',
            })
            .to('[data-arrow]', {
                transform: 'translate(2rem, -50%) scaleX(1)',
                delay: '-1.5',
            })
            .to('[data-third]', {
                opacity: 1,
                delay: '-1.5',
            });

        const arrow = document.querySelector('.nav-pills [data-arrow]');
        const linksAll = $('.tabsNav .nav-link');
        const tabsNav = $('.tabsNav');
        linksAll.hover(
            function () {
                gsap.to('[data-arrow]', {
                    duration: 0.5,
                    transform: `translate(2rem, calc(${
                        $(this).offset().top - tabsNav.offset().top - $(this).height() / 2
                    }px - 1rem)) scaleX(1)`,
                });
            },
            function () {
                const active = $('.tabsNav .nav-link.active');
                gsap.to('[data-arrow]', {
                    duration: 0.5,
                    transform: `translate(2rem, calc(${
                        active.offset().top - tabsNav.offset().top - $(this).height() / 2
                    }px - 1rem )) scaleX(1)`,
                });
            }
        );
    }
};

const tabsMobile = () => {
    const Btn = document.querySelector('.mobileButtonArrow');
    const Tabs = document.querySelectorAll('.navCol button.nav-link');
    Btn.addEventListener('click', () => {
        let activeIndex;
        Tabs.forEach((el, index) => {
            if (el.classList.contains('active')) {
                activeIndex = index;
            }
        });
        // console.log(activeIndex);
        if (activeIndex === Tabs.length - 1) {
            Tabs[0].click();
        } else {
            Tabs[activeIndex + 1].click();
        }
    });
};
