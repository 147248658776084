import { gsap, Sine } from 'gsap';
export const HPAboutSectionAnimation = () => {
    const timeline = gsap.timeline({
        scrollTrigger: {
            scroller: '[data-scroll-container]',
            trigger: '.about-homepage',
            start: 'top center',
        },
        defaults: { ease: 'power4.inOut', duration: 2 },
    });
    if (window.innerWidth > 768) {
        timeline
            .to('.about-homepage .line span', {
                ease: 'power4.out',
                delay: -1,
                duration: 2,
                transform: 'translateY(0) skewY(0)',
                stagger: {
                    amount: 1,
                },
            })
            .to(
                '.letterA path',
                {
                    'stroke-dashoffset': 0,
                    duration: 8,
                    ease: Sine.easeInOut,
                },
                '-=2.6'
            )
            .to(
                '[data-fade-about]',
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 2.4,
                },
                '-=6.5'
            )
            .to(
                '#gdynia',
                {
                    opacity: 1,
                    duration: 1.8,
                },
                '-=7.2'
            );
    } else {
        timeline
            .to(
                '[data-fade-about-mobile]',
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 2.4,
                },
                '-=1.5'
            )
            .to(
                '[data-fade-about-mobile-2]',
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 2.4,
                },
                '-=2.5'
            )
            .to(
                '[data-fade-about]',
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 2.4,
                },
                '-=1.5'
            )
            .to(
                '[data-fade-about-2]',
                {
                    transform: 'translateY(0)',
                    opacity: 1,
                    duration: 2.4,
                },
                '-=1.5'
            );
    }
};
